<template>
    <div class="p-8 text-xs">
        
        <div class="rounded-2xl bg-white p-8">
            
            <div class="">
                    
                        <div class="">
                            <span class="font-semibold text-base">Tambah Category Device</span>
                        </div>
                        <div class="mt-4 grid grid-cols-12 gap-4">
                       <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                       <div class="col-span-12">

                            <ValidationObserver v-slot="{ invalid}" ref="form" >
                            <form @submit.prevent="postData()" class="w-full" >
                            <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name" class="grid grid-cols-12 gap-4 items-center">
                            <label for="" class="block mb-2 col-span-2">Nama*</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.name"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="name"
                                ref="name"
                                id="name"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>

                            <ValidationProvider rules="required" v-slot="{ errors }" name="code" vid="code" class="grid grid-cols-12 gap-4 items-center mt-4">
                            <label for="" class="block mb-2 col-span-2">code*</label>  
                            <div class="col-span-10">
                            <input
                                v-model="addData.code"
                                type="text"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded  text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`"
                                name="code"
                                ref="code"
                                id="code"
                                />
                            <span class="text-red-600 text-xs col-span-10">{{ errors[0] }}</span>
                            </div>
                            </ValidationProvider>


                            <table class="w-full mt-8" >
                                <thead>
                                    <tr>
                                        <th class="text-left">Name</th>
                                        <th class="text-left">Param</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody v-for="(item,index) in device_param" :key="index">
                                    <tr  class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                        <td class="py-4">
                                           {{item.name}}
                                        </td>
                                        <td>
                                           {{item.param}}
                                        </td>
                        
                                        <td>
                                          
                                            <input type="checkbox" name="device_param_id[]" :value="item.id" v-model="addData.device_param_id" id="">
                                        </td>
                                    </tr>
                                 
                                </tbody>
                               
                             
                            </table>


                           
                            <div class="float-right">
                            <button
                                @click="$router.push('/category-device')"
                                :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Batal</button>

                            <button
                                :disabled="invalid"
                                type="submit"
                                :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`"


                            >Simpan</button>
                            </div>

                            
                        </form>
                        </ValidationObserver>
                       </div>
                        </div>                    
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows:100,
        perPage:10,
        limit:5,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        detail:[],
        addData:{
            url:'',
            code:'',
            icon:'',
            parent_id:'',
            device_param_id:[],
            only_developers:0
        },
        delete_id:'',
        modal_edit:false,
        parent:[],
        device_param:[],
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
       
         
        tambahDetail(){
            this.detail.push({
                param:'',
                name:''
            })
        },
        deleteDetail(index) {
            this.detail.splice(index,1)
        },
        async postData(){
            let param = {...this.addData}
           
            this.axios.post('v2/category_device',param,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/category-device')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },
    
    },
    computed:{
      
       
    },
    async created() {
        await this.getDeviceParam()
        console.log(this.device_param)
    },
    components:{
        ValidationProvider,ValidationObserver
    }
}
</script>